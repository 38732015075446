import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';
import Spinner from '~/assets/icons/spinner';

export interface ButtonProps
  extends Pick<
    ComponentPropsWithoutRef<'button'>,
    'disabled' | 'className' | 'onClick' | 'type' | 'style' | 'name' | 'value'
  > {
  icon?: JSX.Element;
  themeType?: 'primary' | 'link' | 'shade' | 'danger' | 'shade-hover';
  contentHeight?: number;
  loading?: boolean;
  customDisabledClassName?: string;
  size?: 'small' | 'default';
  iconPosition?: 'left' | 'right';
  children?: React.ReactNode;
}

/**
 * 
 * @deprecated Use Button from elements package insteadƒ
 */
const ButtonDeprecated: FC<ButtonProps> = ({
  children,
  icon,
  themeType = 'primary',
  contentHeight = 37,
  loading,
  disabled,
  className,
  size = 'default',
  customDisabledClassName,
  iconPosition = 'left',
  onClick,
  ...generalButtonProps
}) => {
  let themeStyles = [
    'bg-primary px-8',
    'text-darkMode-gray-dark',
    !disabled && !loading && 'active:bg-primary/80',
  ];
  if (themeType === 'link') {
    themeStyles = [
      'bg-transparent text-primary px-0 py-[18px] rounded-none active:opacity-60 hover:text-primary/80',
    ];
  } else if (themeType === 'shade') {
    themeStyles = [
      'bg-primary/20 text-primary px-8',
      !disabled && !loading && 'active:opacity-70',
    ];
  } else if (themeType === 'shade-hover') {
    themeStyles = [
      'hover:bg-primary/20 text-primary px-8',
      !disabled && !loading && 'active:opacity-70',
    ];
  } else if (themeType === 'danger') {
    themeStyles = [
      '!bg-darkMode-danger text-darkMode-gray-dark px-8',
      !disabled && !loading && 'active:opacity-60 ',
    ];
  }
  let sizeStyles = '';
  if (size === 'small') {
    sizeStyles = '!text-[15px] !h-10 !py-0 sticky bottom-6 block';
  }

  return (
    <button
      {...generalButtonProps}
      disabled={loading || disabled}
      onClick={!loading && !disabled ? onClick : () => {}}
      className={clsx(
        'rounded-full py-3 text-xl font-bold leading-6',
        (disabled || loading) &&
          'cursor-not-allowed !select-none !bg-darkMode-gray-medium/40 !text-darkMode-gray-dark',
        themeStyles,
        className,
        sizeStyles,
        (disabled || loading) && customDisabledClassName
      )}
    >
      <div
        style={{ height: `${contentHeight}px` }}
        className="grid grid-flow-col items-center justify-center gap-2"
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            {icon}
            {children && (
              <span
                className={clsx(
                  'mt-[2px]',
                  iconPosition === 'right' && 'order-[-1]'
                )}
              >
                {children}
              </span>
            )}
          </>
        )}
      </div>
    </button>
  );
};

export default ButtonDeprecated;
